<template>
  <el-dialog :visible.sync="visible" title="上传素材" width="800px" append-to-body>
    <div class="upload-model">
      <GTree
        :tree-data="treeData"
        :default-expanded-keys="defaultExpandedKeys"
        :default-selected-keys="defaultExpandedKeys[0]"
        :replace-fields="{
          key: 'folderId',
          value: 'folderId',
          label: 'folderName',
          children: 'sub'
        }"
        @select="checkFolderAll"
      />
      <el-upload
        ref="upload"
        class="upload_box"
        accept="image/*,video/*"
        name="file"
        multiple
        drag
        :file-list="fileList"
        :action="QINIU"
        :data="uploadData"
        :on-success="uploadSuccess"
        :on-progress="onProgress"
        :on-error="uploadError"
        :before-upload="beforeUpload"
        :show-file-list="false"
      >
        <img class="upload-icon" src="https://file.aihuamei.cn/base/hash/media_upload.png" alt="">
        <p class="upload_tip">将文件拖放到此处上传，或点击<el-button type="text">上传</el-button></p>
        <p class="upload_tip-des">支持多个文件上传，支持拖拽文件上传</p>
      </el-upload>
    </div>
    <div slot="footer">
      <a-button @click="cancelUpload">取 消</a-button>
    </div>
  </el-dialog>
</template>

<script>
import { QINIU } from '@/utils/config'
import oldAxios from '../utils/oldAxios'
import GTree from './GTree'

export default {
  name: 'Upload',
  components: {
    GTree
  },
  props: {
    folderInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      QINIU,
      visible: false,
      folderId: '',
      uploadToken: '',
      fileList: [],
      defaultExpandedKeys: [],
      treeData: [],
      currentFolder: {}
    }
  },
  computed: {
    uploadData() {
      return {
        token: this.uploadToken,
        'x:sort': +new Date()
      }
    }
  },
  methods: {
    cancelUpload() {
      this.visible = false
    },
    checkFolderAll(data) {
      this.folderId = data.folderId
      this.currentFolder = data
    },
    show(folderId) {
      this.visible = true
      this.folderId = folderId
      this.defaultExpandedKeys = [folderId]
      this.fileList = []
      this.currentFolder = JSON.parse(JSON.stringify(this.folderInfo))
      oldAxios.get('/media/folder/tree').then(res => {
        if (res.code === '0000') {
          this.treeData = [res.data]
        }
      })
    },
    async getUploadToken(fileType) {
      const { folderId } = this
      const param = { folderId, fileType }
      const response = await oldAxios.get('/media/qiniu/token', param)
      if (response.code === '0000') {
        this.uploadToken = response.data.uploadToken
        return true
      } else {
        return false
      }
    },
    beforeUpload(file) {
      return this.getUploadToken(file.type)
    },
    onProgress(event, file, fileList) {
      if (file.response && file.response.code === '0000') {
        this.$emit('progress', {
          fileList: fileList,
          currentFolder: this.currentFolder
        })
      }
      this.visible = false
    },
    uploadSuccess(res, file, fileList) {
      if (res.code !== '0000') {
        this.$message.error(res.msg)
      } else {
        // 上传成功回调方法
        this.$emit('progress', {
          fileList: fileList,
          currentFolder: this.currentFolder
        })
      }
    },
    uploadError(error, file, fileList) {
      this.$emit('progress', {
        fileList: fileList,
        currentFolder: this.currentFolder
      })
      const message = JSON.parse(error.message).error
      this.$message.error(message)
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .el-dialog__footer{
    padding: 10px 16px;
    border-top: 1px #e8e8e8 solid;
  }
  .el-dialog__header{
    padding: 16px 24px;
    border-bottom: 1px #e8e8e8 solid;
    .el-dialog__title{
      color: #475669;
      font-weight: 700;
      font-size: 16px;
    }
    .el-dialog__headerbtn{
      font-size: 20px;
      top: 16px;
    }
  }
  .el-dialog__body{
    padding: 0;
  }
}
.upload-model{
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  height: 400px;
  .g-tree{
    width: 240px;
    height: 100%;
    overflow-y: auto;
    padding: 12px;
    border-right: 1px #e8e8e8 solid;
  }
  .upload_box{
    padding: 12px;
    width: calc(100% - 240px);
    display: flex;
    align-items: center;
    justify-content: center;
    color: $title2;
    .upload-icon{
      display: block;
      width: 240px;
      margin: 48px auto 24px;
    }
    .upload_tip-des{
      color: $title1;
      font-weight: 600;
      margin-top: 24px;
      font-size: 15px;
    }
    ::v-deep{
      .el-upload{
        width: 100%;
        height: 100%;
      }
      .el-upload-dragger{
        width: 100%;
        height: 100%;
        background: #f8f8f8;
      }
    }
  }
}
</style>
