<template>
  <Modal width="500px" :visible.sync="visible" title="文件夹管理" @cancel="close" @ok="checkForm">
    <el-form ref="formInfo" class="custom-folder-info" :model="formInfo" :rules="verifyRule" label-width="110px" @submit.native.prevent>
      <el-form-item label="文件夹路径：" prop="parentId">
        <Dropdown class="custom-file-tree">
          <div class="custom-file-trigger">
            <span v-if="currentParentName">{{ currentParentName }}</span>
            <span v-else class="placeholder">请选择</span>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </div>
          <DropdownMenu slot="dropdown" class="custom-file-content">
            <GTree
              :tree-data="options"
              :default-expanded-keys="defaultExpandedKeys"
              :default-selected-keys="defaultExpandedKeys[0]"
              :replace-fields="{
                key: 'folderId',
                value: 'folderId',
                label: 'folderName',
                children: 'sub'
              }"
              @select="checkFolderAll"
            />
          </DropdownMenu>
        </Dropdown>
      </el-form-item>
      <el-form-item label="文件夹名称：" prop="name">
        <el-input v-model="formInfo.name" class="form_item" @keyup.native.enter="checkForm"></el-input>
      </el-form-item>
    </el-form>
  </Modal>
</template>

<script>
import oldAxios from '../utils/oldAxios'
import GTree from '@/components/GTree'
import { Modal } from 'ant-design-vue'
import { DropdownItem, DropdownMenu, Dropdown } from 'element-ui'
export default {
  name: 'FolderInfo',
  components: {
    GTree,
    Modal,
    Dropdown,
    DropdownItem,
    DropdownMenu
  },
  data() {
    return {
      defaultExpandedKeys: [],
      visible: false,
      saveLoading: false,
      formInfo: {
        name: '',
        parentId: ''
      },
      isEdit: false,
      currentParentName: '',
      verifyRule: {
        parentId: [
          { required: true, message: '请选择文件夹路径' }
        ],
        name: [
          { required: true, message: '请输入文件夹名称' }
        ]
      }
    }
  },
  computed: {
    options() {
      return this.$store.getters.folderList
    }
  },
  methods: {
    checkFolderAll(data) {
      this.currentParentName = data.folderName
      this.formInfo.parentId = data.folderId
    },
    show(data, edit) {
      this.visible = true
      if (this.$refs.formInfo) this.$refs.formInfo.resetFields()
      if (edit) {
        this.currentParentName = data.parentName
        const { folderName: name, folderId } = JSON.parse(JSON.stringify(data))
        this.formInfo = { name, folderId, parentId: data.parentId }
        this.defaultExpandedKeys = [data.parentId === '0' ? data.folderId : data.parentId]
      } else {
        this.defaultExpandedKeys = [data.folderId]
        this.currentParentName = data.folderName
        this.formInfo.parentId = data.folderId
      }
      this.isEdit = edit
    },
    checkForm() {
      this.$refs.formInfo.validate(async(valid) => {
        if (valid) {
          const { name, parentId, folderId: id } = this.formInfo
          this.saveLoading = true
          let response, message, responseData
          if (this.isEdit) {
            response = await oldAxios.post('/media/folder/update', { id, name, parentId })
            message = '修改成功'
            responseData = response.data
          } else {
            response = await oldAxios.post('/media/folder/add', { parentId, name })
            message = '新增成功'
            responseData = response.data
          }
          this.saveLoading = false
          if (response.code === '0000') {
            this.$message.success(message)
            this.$emit('change', responseData)
            this.visible = false
          }
        }
      })
    },
    close() {
      this.visible = false
      this.formInfo.parentId = ''
      this.formInfo.name = ''
      this.currentParentName = ''
      this.defaultExpandedKeys = []
    }
  }
}
</script>
<style lang="scss" scoped>
.form_item {
  width: 330px;
}
.custom-folder-info{
  ::v-deep{
    .el-form-item__label{
      font-weight: bold;
      color: var(--titleColor);
    }
  }
}
.custom-file-tree{
  width: 330px;
  .custom-file-trigger{
    cursor: pointer;
    -webkit-appearance: none;
    background-color: #FFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: 0;
    padding: 0 10px 0 15px;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .placeholder{
      color: #c6cad0;
    }
    .el-icon--right{
      font-size: 14px;
      color: #c6cad0;
    }
  }
}
.custom-file-content{
  width: 330px;
  height: 240px;
  overflow: auto;
  padding: 12px;
}
</style>
