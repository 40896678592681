<template>
  <div class="doris-view">

    <div class="file-tree">
     <el-input
        v-model="queryKey"
        class="input-search"
        placeholder="输入文件夹名称搜索"
        enter-button="搜索"
        allow-clear
        size="small"
        clearable
      >
        <el-button slot="append" size="small" type="primary" @click="getFolderAll">搜 索</el-button>
      </el-input>
      <a-spin v-if="searchLoad" class="depart-tree-spin" />
      <div class="head-editor">
        <p>文件夹</p>
        <div>
          <a-button size="small" type="link" @click="addFolder">新增</a-button>
          <a-button size="small" type="link" @click="editFolder">编辑</a-button>
          <a-button size="small" type="link" @click="deleteFolder">删除</a-button>
        </div>
      </div>
      <GTree
          :tree-data="treeData"
          :default-expanded-keys="defaultExpandedKeys"
          :default-selected-keys="defaultExpandedKeys[0]"
          :replace-fields="{
          key: 'folderId',
          value: 'folderId',
          label: 'folderName',
          children: 'sub'
        }"
          @select="checkFolderAll"
      />
    </div>

    <div v-loading="isLoad" class="file-content">
      <div class="banner_box">
        <div class="breadcrumb-tabs">
          <span v-for="item in breadcrumb" :key="item.folderId">{{ item.folderName }} <b style="font-weight: normal;margin-right: 4px;">/</b></span>
        </div>
        <div v-if="mediaList.length" class="check_info">
          <el-checkbox v-model="checkAll" class="check_all" @change="checkAllChange">全选</el-checkbox>
          <template v-if="checkList.length">
            <span class="check_num">已选中<i>{{ checkList.length }}</i>个文件</span>
            <el-button type="danger" plain class="button" size="mini" @click="deleteFile">删除</el-button>
            <el-button class="button" size="mini" @click="moveFile">移动</el-button>
          </template>
        </div>
       <div class="file_type">
         <el-button class="upload-btn" type="primary" size="mini" @click="uploadFile">上传</el-button>
         <el-radio-group v-model="fileType" size="mini" @change="getFileList">
           <RadioButton label="all">全部</RadioButton>
           <RadioButton label="image">图片</RadioButton>
           <RadioButton label="video">视频</RadioButton>
         </el-radio-group>
       </div>
      </div>
      <template v-loading="loading" v-if="showMediaList.length || (currentFolder.sub&&currentFolder.sub.length) ">
        <el-checkbox-group v-model="checkList" class="check_box_group" @change="checkChange">
          <template v-if="currentFolder.sub">
            <div
              v-for="folder in currentFolder.sub"
              :key="folder.id"
              class="media_item"
              @dblclick="checkFolder(folder)"
            >
              <div class="main_box default-img">
                <img src="@/assets/folder.png" class="img_folder">
              </div>
              <div class="media_name">
                <span>{{ folder.folderName }}</span>
                <span>文件夹</span>
              </div>
            </div>
          </template>
          <div
            v-for="(media, mediaIndex) in showMediaList"
            :key="media.id"
            class="media_item"
            :class="{'selectd-media':
            checkList.includes(media.id)}"
            @click="checkMedia(media)"
            @dblclick="previewImg(media, mediaIndex)"
          >
            <div class="hover_box">
              <span v-if="media.type !== 'video'" title="预览图片" @click.stop="previewImg(media, mediaIndex)"><i class="el-icon-picture-outline"></i></span>
              <span title="删除图片" @click.stop="deleteFile(media)"><i class="el-icon-delete"></i></span>
              <span title="复制链接" @click.stop="copyLink(media)"><i class="el-icon-link"></i></span>
              <span title="查看大图" @click.stop="showBigImg(media)"><i class="el-icon-copy-document"></i></span>
            </div>
            <div v-if="media.status === 'success'" class="main_box">
              <el-checkbox :label="media.id" class="check_box" @change="checkMedia(media)">
                <div></div>
              </el-checkbox>
              <img
                v-if="media.type === 'video'"
                class="img"
                :src="media.url + '&vframe/png/offset/3|imageMogr2/thumbnail/!12p/gravity/center/crop/!148x148'"
              >
              <img v-else :src="media.url + '?imageslim&imageView2/1/w/144/h/149/q/70'" class="img">
            </div>
            <div v-else-if="media.status === 'uploading'" class="main_box loading">
              <p><i class="el-icon-loading"></i>上传中...</p>
            </div>
            <div v-else-if="media.status === 'ready'" class="main_box loading">
              <p><i class="el-icon-loading"></i>等待中...</p>
            </div>
            <div v-else class="main_box loading">
              <p><i class="el-icon-picture-outline"></i>上传失败</p>
            </div>
            <div class="media_name">
              <span>{{ media.name }}</span>
              <span>{{ media.size }}</span>
            </div>
          </div>
        </el-checkbox-group>
      </template>
     <div v-else-if="!isLoad" class="empty-custom-content">
       <Empty
         class="empty-custom"
         image="https://file.aihuamei.cn/base/hash/media_upload.png"
       >
         <div slot="description" @click="uploadFile">
           <p> 您还没有上传文件 </p>
           <a-button type="primary">立即上传</a-button>
         </div>
       </Empty>
     </div>
    </div>

    <div class="doris-footer">
      <el-popover
        placement="top"
        width="220"
        trigger="click"
      >
        <div class="capacity_detail">
          <p v-if="capacityInfo.imageNum">
            <span class="name">图片：</span>
            <span class="num">{{ capacityInfo.imageNum }}个文件 </span>
            <span class="num">{{ capacityInfo.usedImage }}</span>
          </p>
          <p v-if="capacityInfo.videoNum">
            <span class="name">视频：</span>
            <span class="num">{{ capacityInfo.videoNum }}个文件 </span>
            <span class="num">{{ capacityInfo.usedVideo }}</span></p>
        </div>
        <div slot="reference" class="capacity_box">
          <span>已用空间：<i>{{ capacityInfo.usedTotle }}</i></span>
          <i class="el-icon-question"></i>
        </div>
      </el-popover>
      <div style="display: flex;align-items: center">
        <span class="total" style="display: inline-block;font-size: 14px; color: #909399;margin-right: 12px;">共 <b style="color: #475669; font-weight: 400;">{{ pageInfo.total }}</b> 条记录</span>
        <Pagination
            :current="pageInfo.current"
            :page-size-options="['20', '50', '100', '200']"
            show-size-changer
            :page-size="pageInfo.size"
            :total="pageInfo.total"
            @change="pageChange"
            @showSizeChange="showSizeChange"
        />
      </div>
    </div>

    <Upload ref="upload" :folder-info="currentFolder" @progress="onProgress" />

    <MoveFile ref="moveFile" @change="getFileList" />

    <FolderInfo ref="folderInfo" @change="getFolderAll" />

    <ImageView v-if="isShowImageView" :on-close="closeViewer" :url-list="previewImgList" />

  </div>
</template>

<script>
import Upload from '@/components/Upload'
import MoveFile from '@/components/MoveFile'
import FolderInfo from '@/components/FolderInfo'
import GTree from '@/components/GTree'
import oldAxios from '../../utils/oldAxios'
import { Spin } from 'ant-design-vue'
import { RadioButton } from 'element-ui'
import { Empty, Pagination } from 'ant-design-vue'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  name: 'TestPage',
  components: {
    Upload, MoveFile, FolderInfo, GTree, Spin, RadioButton, Empty, ImageView: ElImageViewer, Pagination
  },
  data() {
    return {
      isLoad: true,
      defaultExpandedKeys: [],
      searchLoad: false,
      queryKey: '',
      currentFolder: {},
      fileType: 'all',
      pageInfo: { size: 20, current: 1, total: 0 },
      loading: true,
      checkList: [],
      mediaList: [],
      checkAll: false,
      capacityInfo: {},
      uploadingList: [],
      treeData: [],
      breadcrumb: [],
      previewImgList: [],
      isShowImageView: false
    }
  },
  computed: {
    showMediaList() {
      return [...this.uploadingList, ...this.mediaList]
    },
  },
  mounted() {
    this.getFolderAll()
    this.getCapacityInfo()
  },
  methods: {
    closeViewer() {
      this.isShowImageView = false
    },
    previewImg(file, index) {
      if (file.type === 'video') return
      const srcList = this.mediaList.map(v => v.url)
      const startImg = srcList.splice(index, srcList.length)
      const endImg = srcList.splice(0, index)
      this.previewImgList = [...startImg, ...endImg]
      this.isShowImageView = true
    },
    checkMedia(item) {
      if (this.checkList.includes(item.id)) {
        this.checkList.splice(this.checkList.findIndex(v => v === item.id), 1)
      } else {
        this.checkList.push(item.id)
      }
    },
    addFolder() {
      this.$refs.folderInfo.show(this.currentFolder, false)
    },
    editFolder() {
      this.$refs.folderInfo.show(this.currentFolder, true)
    },
    deleteFolder() {
      const self = this
      this.$antConfirm({
        title: '删除文件夹',
        content: '删除后，文件夹及其数据均被删除，无法恢复。\n 是否确定删除此文件夹？',
        width: '500px',
        okText: '删除',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          const { folderId: id, parentId } = self.currentFolder
          oldAxios.post('/media/folder/delete', { id }).then(res => {
            if (res.code === '0000') {
              self.$message.success('文件夹删除成功')
              self.currentFolder = { folderId: parentId }
              self.getFolderAll()
            }
          })
        },
        onCancel() {}
      })
    },
    uploadFile() {
      this.$refs.upload.show(this.currentFolder.folderId)
    },
    deleteFile(media) {
      const that = this
      this.$antConfirm({
        title: '删除文件',
        content: '删除后，文件及其数据均被删除，无法恢复。\n 是否确定删除此文件？',
        width: '500px',
        okText: '删除',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          let ids
          if (media.id) {
            ids = [media.id]
          } else {
            ids = that.checkList
          }
          oldAxios.post('/media/media/delete', { ids: JSON.stringify(ids) }).then(res => {
            if (res.code === '0000') {
              that.$message.success('删除成功')
              that.getFileList()
            }
          })
        },
        onCancel() {}
      })
    },
    moveFile() {
      const selectedFiles = this.mediaList.filter(v => this.checkList.includes(v.id))
      this.$refs.moveFile.show(selectedFiles, this.currentFolder)
    },
    checkAllChange(value) {
      if (value) {
        this.checkList = this.mediaList.map(item => item.id)
      } else {
        this.checkList = []
      }
    },
    showSizeChange(page, size) {
      this.pageInfo.size = size
      this.getFileList()
    },
    pageChange(data) {
      this.pageInfo.current = data
      this.getFileList()
    },
    checkChange(data) {
      this.checkAll = data.length === this.mediaList.length
    },
    checkFolder(data) {
      this.defaultExpandedKeys = [data.folderId]
      this.checkFolderAll(data)
      this.$forceUpdate()
    },
    onProgress(data) {
      let allFinish = true
      this.uploadingList = data.fileList.map(item => {
        if (item.status === 'uploading' || item.status === 'ready') {
          allFinish = false
        }
        if (item.response && item.response.data) {
          return { ...item.response.data, status: 'success' }
        } else {
          return { ...item, id: item.uid }
        }
      }).reverse()
      if (allFinish) {
        this.$message.success('上传完成')
        this.mediaList = [...this.uploadingList, ...this.mediaList]
        this.uploadingList = []
        this.breadcrumb = this.filterFolder(this.treeData, data.currentFolder.folderId)
        this.defaultExpandedKeys = [data.currentFolder.folderId]
        this.currentFolder = data.currentFolder
        this.getFileList()
      }
    },
    async getCapacityInfo() {
      const response = await oldAxios.get('/media/setting/capacity')
      this.capacityInfo = response.data
    },
    async getFolderAll(operatingData) {
      this.defaultExpandedKeys = []
      await this.$store.dispatch('getFolderData')
      this.searchLoad = true
      oldAxios.get('/media/folder/tree', { queryKey: this.queryKey }).then(res => {
        if (res.code === '0000') {
          this.treeData = [res.data]
          this.fileType = 'all'
          if (operatingData && operatingData.folderId) {
            this.defaultExpandedKeys = [operatingData.folderId]
            this.checkFolderAll(operatingData)
          } else {
            this.defaultExpandedKeys = [res.data.folderId]
            this.checkFolderAll(res.data)
          }
        }
        this.searchLoad = false
      })
    },
    checkFolderAll(data) {
      this.breadcrumb = this.filterFolder(this.treeData, data.folderId)
      this.currentFolder = data
      this.pageInfo.total = 0
      this.pageInfo.current = 1
      this.getFileList()
    },
    filterFolder(data2, nodeId2) {
      var arrRes = [];
      if (data2.length == 0) {
        if (!!nodeId2) {
          arrRes.unshift(data2)
        }
        return arrRes;
      }
      let rev = (data, nodeId) => {
        for (var i = 0, length = data.length; i < length; i++) {
          let node = data[i];
          if (node.folderId == nodeId) {
            arrRes.unshift(node)
            rev(data2, node.parentId);
            break;
          }
          else {
            if (!!node.sub) {
              rev(node.sub, nodeId);
            }
          }
        }
        return arrRes;
      };
      arrRes = rev(data2, nodeId2);
      return arrRes;

    },
    async getFileList() {
      this.isLoad = true
      const { current, size } = this.pageInfo
      const { folderId } = this.currentFolder
      const param = { page: current, size, folderId, type: this.fileType }
      this.loading = true
      const response = await oldAxios.get('/media/media/list', param)
      this.loading = false
      this.checkList = []
      this.checkAll = false
      this.isLoad = false
      this.mediaList = response.data.list.map(item => {
        return { ...item, status: 'success' }
      })
      this.pageInfo.total = response.data.total
    },
    copyLink(data) {
      const transfer = document.createElement('input')
      document.body.appendChild(transfer)
      transfer.value = data.url
      transfer.focus()
      transfer.select()
      if (document.execCommand('copy')) {
        document.execCommand('copy')
      }
      transfer.blur()
      document.body.removeChild(transfer)
      this.$message.success('复制成功')
    },
    showBigImg(data) {
      window.open(data.url)
    }
  }
}
</script>

<style lang="scss" scoped>
.doris-view{
  width: 100%;
  height: 100%;
  padding: 12px;
  display: flex;
  position: relative;
  .doris-footer{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 48px;
    line-height: 48px;
    background: #fff;
    padding: 0 10px;
    border-top: 1px $themeBg solid;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .head-editor{
    width: calc(100% - 16px);
    border-radius: 4px;
    display: flex;
    align-items: center;
    color: $title1;
    background: $themeBg;
    font-size: 14px;
    font-weight: 600;
    padding: 6px 0 6px 12px;
    &>div{
      margin-left: auto;
      ::v-deep{
        .el-button{
          padding: 0 7px;
          margin: 0;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }
  .file-tree{
    width: 260px;
    height: calc(100% - 45px);
    overflow-y: auto;
    background: #fff;
    box-shadow: $cardShadow;
    border-radius: 4px;
    padding: 16px 0 0 16px;
    position: relative;
    .g-tree{
      height: calc(100% - 80px);
      overflow: auto;
    }
    .depart-tree-spin{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, .8);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 20;
    }
    .input-search{
      margin-bottom: 12px;
    }
    ::v-deep{
      .el-input{
        padding-right: 16px;
      }
      .el-input-group__append{
        .el-button--primary{
          height: 32px;
          background: #409EFF;
          border-color: #409EFF;
          color: #fff;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
    .depart-tree-spin{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .file-content{
    width: calc(100% - 204px);
    margin-left: 12px;
    height: calc(100% - 45px);
    background: #fff;
    box-shadow: $cardShadow;
    border-radius: 8px;
    overflow: hidden;
    .check_box_group {
      width: 100%;
      height: calc(100% - 54px);
      padding-top: 12px;
      background: #FFF;
      overflow: auto;
      .media_item {
        width: 146px;
        float: left;
        margin: 0 0 12px 12px;
        position: relative;
        cursor: pointer;
        border: 1px solid #b9b9b9;
        box-shadow: $cardShadow;
        border-radius: 4px;
        overflow: hidden;
        .hover_box {
          display: none;
          width: 100%;
          position: absolute;
          z-index: 10;
          bottom: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.7);
          color: #fff;
          line-height: 32px;
          height: 32px;
          font-size: 14px;
          text-align: right;
          font-weight: bold;
          i {
            margin: 0 8px;
          }
        }
        &:hover .hover_box {
          display: block;
        }
        .main_box {
          width: 100%;
          height: 149px;
          position: relative;
          box-sizing: border-box;
          .img_folder {
            display: block;
            width: 100%;
            height: 100%;
            margin: 0 auto;
          }
          .img {
            width: 100%;
            height: 100%;
            vertical-align: bottom;
          }
          .check_box {
            position: absolute;
            top: 4px;
            left: 4px;
          }
          &.loading {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            i {
              font-size: 22px;
              vertical-align: middle;
              margin-right: 6px;
              margin-top: -4px;
            }
          }
        }
        .default-img{
          width: 119px;
          height: 119px;
          padding: 15px 0;
          box-sizing: content-box;
          margin: 0 auto;
        }
        .media_name {
          width: 100%;
          text-align: left;
          padding: 6px;
          &>span{
            text-align: left;
            display: block;
            font-size: 12px;
            overflow: hidden;
            cursor: pointer;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: $title2;
            &:first-child{
              color: $title1;
              font-weight: 500;
              line-height: 32px;
            }
          }
        }
      }
      .selectd-media{
        border-color: #409EFF;
        box-shadow: 2px 0 7px 0 #409EFF;
      }
    }
  }
  .banner_box {
    display: flex;
    align-items: center;
    background: #FFF;
    padding: 12px;
    height: 53px;
    border-bottom: 1px $themeBg solid;
    .breadcrumb-tabs{
      font-size: 14px;
      color: $title1;
      span{
        &:last-child{
          color: $title2;
          b{
            display: none;
          }
        }
      }
    }
    .check_info {
      float: left;
      line-height: 18px;
      margin-left: 12px;
      .check_all {
        display: inline-block;
        height: 28px;
        line-height: 28px;
      }
      .check_num {
        margin-left: 20px;
        font-size: 14px;
        line-height: 18px;
        i {
          margin: 0 3px;
          color: #3083f2;
          font-size: 15px;
        }
      }
      .button {
        margin-left: 10px;
      }
    }
    .file_type {
      margin-left: auto;
      margin-right: 12px;
      .upload-btn{
        width: 112px;
        margin-right: 12px;
      }
    }
  }
  .empty_box {
    position: relative;
    text-align: center;
    padding-top: 100px;
    font-size: 20px;
    margin: 0 16px;
    color: #999;
    background: #fff;
    height: calc(100% - 222px);
    .icon-empty {
      font-size: 100px;
    }
  }
  .capacity_box {
    position: absolute;
    line-height: 30px;
    font-size: 14px;
    height: 30px;
    left: 20px;
    bottom: 7px;
    cursor: pointer;
    span {
      i {
        color: #3083f2;
        margin-left: 2px;
      }
    }
    .el-icon-question {
      margin-left: 12px;
      color: #ffae0c;
      font-size: 16px;
    }
  }
  .capacity_detail {
    p {
      line-height: 30px;
    }
    .name {
      width: 60px;
    }
    .num {
      display: inline-block;
      width: 76px;
    }
  }
  .pagination{
    margin-left: auto;
  }
}
  .empty-custom{
    ::v-deep{
      .ant-empty-image{
        height: auto;
        img{
          width: 240px;
          height: auto;
        }
      }
      .ant-empty-description{
        color: $title2;
        font-weight: bold;
        margin-top: 48px;
        p{
          margin-bottom: 12px;
        }
      }
      .ant-empty-footer{
      }
    }
  }
  .empty-custom-content{
    width: 100%;
    height: calc(100% - 60px);
    padding-top: 32px;
  }
</style>
